<template>
	<div style="margin-top: 20px;">
		<div class="sameWidth">
			<div style="margin:0;text-align: center;">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/companyActive' }">公司业务</el-breadcrumb-item>
					<el-breadcrumb-item>招标审计</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			
			<div style="margin:0;text-align: center;">
				<h1 class="font2" style="margin-bottom: 50px;">招标审计</h1>
				<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<div style="width: 50%;display: inline-block;vertical-align: middle;">
					<img src="../../../assets/images/companyActive/公司业务—审计.jpg" style="width: 100%;"/>
				</div><div style="width: 50%;display: inline-block;vertical-align:top;">
					<p style="text-indent: 2em;padding-left: 20px;font-size: 16px;color: #595959;line-height: 30px;text-align: left;" >
						招投研究院具有专业的招标审计团队，通过对招投标制度和流程、招投标实施过程、招标方案及相关文件进行审查，从合法性、规范性层面提出整改建议。
					</p>
				</div>
			</div>
			<div style="margin:0;text-align: center;">
				<h1 class="font2" style="margin-bottom: 50px;">全过程审计</h1>
				<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<div style="display: flex;">
					<div style="flex-grow: 1;width: 0;">
						<img src="../../../assets/images/tenderTotal/1.jpg" style="width: 100%;"/>
					</div>
					<div style="flex-grow:1;width: 0;">
						<p class="clumn">
							<span class="blue">事前</span>
							<span>规避招标</span>
							<span>虚假招标</span>
							<span>条款违约</span>
						</p>
						<p class="clumn">
							<span class="blue">事中</span>
							<span>程序违法</span>
							<span>评分不当</span>
							<span>围标串标</span>
						</p>
						<p class="clumn">
							<span class="blue">事后</span>
							<span>违法变更</span>
							<span>违规结算</span>
							<span>资料不清</span>
						</p>
					</div>
				</div>
			</div>
			<div style="margin:100px 0;text-align: center;" id="good">
				<h1  class="font2">服务优势</h1>
				<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<div style="display: flex;">
					<div style="flex:1;padding: 0%;">
						<p>资质齐全</p>
					</div>
					<div style="flex:1;padding: 0% 15px;"><p>实力团队</p></div>
					<div style="flex:1;padding: 0%;"><p>业绩众多</p></div>
				</div>
				<div style="display: flex;">
					<div style="flex:1;padding: 0%;"><p>经验丰富</p></div>
					<div style="flex:1;padding: 0% 15px;"><p>技术支撑</p></div>
					<div style="flex:1;padding: 0%;"><p>涵盖面广</p></div>
				</div>
			</div>
			<div style="margin:100px 0;text-align: center;display: none;" class="sameWidth">
				<h1  class="font2">经典案例</h1>
				<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<div style="display: flex;" class="banner4">
					<div style="flex:1;padding: 0;" v-for="item in youshi" :key="item.id">
						<div style="margin:5px;box-shadow: 10px 10px 5px #888888;height: 100%;">
							<img :src="item.src" class="image" />
							<div style="padding:0 20px">
								<h3>{{ item.title }}</h3>
								<p style="color: #909399;text-align: left;line-height: 25px;">{{ item.desc }}</p>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<style scoped>
	.image{width: 100%;}
	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
	}
	.borderBottom{
		margin-top: -40px;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 50px;
	}
	.clumn{margin-bottom: 50px;text-align: left;margin-left: 20px;background-color: #EBEEF5;height:50px;line-height:50px;}
	.clumn .blue{background-color:#409EFF;color: #fff;height: 50px;display: inline-block;}
	.clumn span{padding:0 4%;}
	#good p{background-color:#409EFF;color: #fff;font-size: 24px;font-weight: 500;padding: 20px 0;margin:5% 0;}
</style>


<script>
export default {
	name:'tenderTotal',
	metaInfo: {
		title: '招标审计',
		meta: [{
				name: 'keywords',
				content: '招标审计,规避招标,虚假招标,围标串标,招标方案,招投标制度'
			},
			{
				name: 'description',
				content: '招投研究院具有专业的招标审计团队，通过对招投标制度和流程、招投标实施过程、招标方案及相关文件进行审查，从合法性、规范性层面提出整改建议。'
			}
		]
	},
  data() {
    return {
		screenWidth:1300,
		headersrc: require("../../../assets/image/公司前台.jpg"),
		youshi: [
			{
			id: 1,
			src: require("../../../assets/images/tenderAgent/经验丰富.jpg"),
			title: "业务经验丰富",
			desc:'　　核心团队具有十余年招标代理、政府采购代理、招标规范化审计经验，累计完成代理额超过100亿元。',
			},
			{
			id: 2,
			src: require("../../../assets/images/tenderAgent/专业团队.jpg"),
			title: "专业团队",
			desc:'　　招投研究院80%以上为本科以上学历或中高级以上职称；聘请了原广东省财政厅政府采购监管处退休专家、,华南理工大学工商管理学院教授、广东正大方略律师事务所高级合伙人等一批专家团队，强大的专家团队确保项目顺利履行。',
			},
			{
			id: 3,
			src: require("../../../assets/images/tenderAgent/良好口碑.jpg"),
			title: "良好口碑",
			desc:'　　近年来服务广东、湖南、湖北、山东、广西、云南等地区的 300 多家企事业单位，取得了良好的社会声誉。目前主要行业包括：,（1）电力电网及其供应商；（2）运营商及其供应商；（3）政府采购各行各业优秀供应商。',
			}
		],
    };
  },
	mounted:function(){
		this.bus.$emit('headerImg',this.headerImg);
		this.screenWidth=document.documentElement.clientWidth;
		window.addEventListener('resize',()=>{
			this.screenWidth=document.documentElement.clientWidth;
		})
	}
};
</script>
